<template>
  <div class="home">
    <Search />
    <div class="solutions-container">
      <div v-if="solutionsLoading" class="lds-facebook">
        <div></div>
        <div></div>
        <div></div>
      </div>
      <Solutions
        v-show="!solutionsLoading"
        v-for="solution of solutions"
        :key="solution.id"
        :data="solution"
      />
      <ButtonSupport />
      <div class="footer"></div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapActions } from "vuex";
import Search from "@/components/Search.vue";
import Solutions from "@/components/Solutions.vue";
import ButtonSupport from "@/components/ButtonSupport.vue";
export default {
  name: "Home",
  components: {
    Search,
    Solutions,
    ButtonSupport,
  },
  methods: {
    ...mapActions(["registerDomain"]),
    ...mapActions(["registerRut"]),
  },
  computed: {
    solutions() {
      return this.$store.state.solution.solutions;
    },
    solutionsLoading() {
      return this.$store.state.solution.loading;
    },
  },
  mounted() {
    this.registerDomain(this.$route.params.sistema);
    this.registerRut(
      this.$route.params.rut != "null" ? this.$route.params.rut : ""
    );

    window.parent.postMessage(
      {
        load: true,
      },
      "*"
    );
  },
};
</script>
<style>
.lds-facebook {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-facebook div {
  display: inline-block;
  position: absolute;
  left: 8px;
  width: 16px;
  background: #006588;
  animation: lds-facebook 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
}
.lds-facebook div:nth-child(1) {
  left: 8px;
  animation-delay: -0.24s;
}
.lds-facebook div:nth-child(2) {
  left: 32px;
  animation-delay: -0.12s;
}
.lds-facebook div:nth-child(3) {
  left: 56px;
  animation-delay: 0;
}
@keyframes lds-facebook {
  0% {
    top: 8px;
    height: 64px;
  }
  50%,
  100% {
    top: 24px;
    height: 32px;
  }
}
.home {
  height: -webkit-fill-available;
  /* width: 500px; */
  overflow: hidden;
  display: flex;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  display: -ms-flexbox;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}
.solutions-container {
  overflow-x: auto;
}
</style>
