<template>
  <router-link :to="{ name: 'Solution', params: { id: data.id } }">
    <div class="card">
      <div class="card-body">
        <p class="mb-0">{{ data.solution }}</p>
      </div>
    </div>
  </router-link>
</template>

<script>
export default {
  name: "Solutions",
  props: ["data"],
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.one-line-text {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.card {
  margin: 20px 10px;
  padding: 0px 10px;
  border: initial;
  background: #fff;
  border-radius: 5px;
  /*box-shadow: 1px 3px 7px 0px #999;*/
  box-shadow: 0px 1px 3px 0px #999;
  width: fit-content;
}
.card-body {
  padding: 2px;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
}
p {
  text-align: initial;
  margin: 5px;
}
a {
  text-decoration: initial;
  color: initial;
}
</style>
