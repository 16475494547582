<template>
  <div class="search-content">
    <label for="">¿En qué podemos ayudarte?</label>
    <form
      id="search-form"
      v-on:submit.prevent
    >
      <input
        id="search-input"
        placeholder="Describe el problema"
        v-model="search"
        @input="searchSolutions($event.target.value)"
        class="form-control"
        type="text"
        autocomplete="off"
        name="search-solutions"
      />
      <button class="search-search__search-button" aria-label="Buscar">
        <svg class="search-search__search-icon" viewBox="0 0 24 24">
          <path
            d="M20.49 19l-5.73-5.73C15.53 12.2 16 10.91 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.41 0 2.7-.47 3.77-1.24L19 20.49 20.49 19zM5 9.5C5 7.01 7.01 5 9.5 5S14 7.01 14 9.5 11.99 14 9.5 14 5 11.99 5 9.5z"
          ></path>
          <path d="M0 0h24v24H0V0z" fill="none"></path>
        </svg>
        <div></div>
      </button>
      <button
        type="button"
        @click="clearSearch()"
        v-if="search.length"
        class="search-search__clear-button"
        aria-label="Borrar contenido del cuadro de búsqueda"
      >
        <svg class="promoted-search__clear-icon" viewBox="0 0 48 48">
          <path
            d="M38 12.83L35.17 10 24 21.17 12.83 10 10 12.83 21.17 24 10 35.17 12.83 38 24 26.83 35.17 38 38 35.17 26.83 24z"
          ></path>
        </svg>
      </button>
    </form>
  </div>
</template>

<script>
export default {
  name: "Search",
  data() {
    return {
      search: "",
    };
  },
  methods: {
    clearSearch() {
      this.search = "";
    },
    searchSolutions(string) {
      let data = {
        search: string.replace(/[^a-zA-Z0-9 ]/g, ""),
        system: this.$store.state.sesion.system,
        rut: this.$store.state.sesion.rut,
      };
      this.$store.dispatch("searchSolutions", data);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.search-content {
  padding: 16px;
  max-width: 100%;
  flex: 0 0 auto;
  box-sizing: border-box;
  flex-wrap: wrap;
  background: #006588;
  color: white;
}
.search-search__clear-button {
  right: 10px;
}
.search-search__search-button {
  left: 10px;
}

.search-search__search-button,
.search-search__clear-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  height: 2.5rem;
  padding: 0;
  position: absolute;
  top: 0;
  width: 20px;
  font-size: 0.875rem;
}
#search-form {
  margin: 0 auto;
  width: 100%;
  position: relative;
  margin-top: 8px;
}
#search-input {
  outline: currentcolor none medium;
  background-color: #ffffff;
  border: none;
  border-radius: 0.5rem;
  box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.3),
    0 1px 3px 1px rgba(60, 64, 67, 0.15);
  box-sizing: border-box;
  color: #202124;
  display: block;
  font-size: 1rem;
  height: 2.5rem;
  padding: 0 2.5rem 0 2.5rem;
  width: 100%;
  -webkit-appearance: none;
}
</style>
