export default {
  state: {
    domain: "",
    system: 1,
    rut: "",
  },
  mutations: {
    setDomain(state, payload) {
      state.domain = payload;
      state.system = payload.includes("virtualeduca") ? 2 : 1;
    },
    setRut(state, payload) {
      state.rut = payload;
    },
  },
  actions: {
    registerDomain({ commit }, domain) {
      commit("setDomain", domain);
    },
    registerRut({ commit }, rut) {
      commit("setRut", rut);
    },
  },
};
