import Vue from "vue";
import Vuex from "vuex";
import solution from "./modules/solution";
import sesion from "./modules/sesion";
import support from "./modules/support";
Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    solution,
    sesion,
    support,
  },
});
