<template>
  <div class="container">
    <div class="search-content">
      <img
        @click="goBack()"
        width="30px"
        height="auto"
        style="float: left; position: absolute; top: 10px; left: 10px"
        :src="require('@/assets/back.png')"
      />
      <h2 class="title">Ticket registrado exitosamente</h2>
    </div>
    <div class="card">
      <div class="card-body">
        <div class="content-details">
          Para consultar el estatus de su solicitud debe ingresar al siguiente
          <a target="_blank" :href="url">link</a><br />
          N° de seguimiento<br />
          <div style="text-align: -webkit-center">
            <p
              style="
                font-size: 30px;
                background: beige;
                padding: 12px;
                width: max-content;
              "
            >
              {{ this.$store.state.support.support.id }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const urlApi = process.env.VUE_APP_GESTIONA_URL;
export default {
  name: "Solution",
  data() {
    return {
      url: "#",
    };
  },
  methods: {
    goBack() {
      this.$router.push(
        "/app/" +
          this.$store.state.sesion.domain +
          "/" +
          this.$store.state.sesion.rut
      );
    },
  },
  mounted() {
    this.url = urlApi + "/soporte-tecnico/soportes/seguimiento";
  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.one-line-text {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.content-details img {
  max-width: 100% !important;
  max-height: 100% !important;
}
.content-details {
  max-width: 100% !important;
  max-height: 100% !important;
  text-align: center;
}
.content-details p,
.content-details span,
.content-details strong,
.content-details div,
.content-details iframe,
.content-details frame {
  max-width: -webkit-fill-available !important;
  max-width: -moz-available !important;
}
.title {
  font-size: 1em;
  margin: 0 35px;
  color: #fff;
  display: inline-block;
  box-shadow: 0 25px 0 -23px #5cc7a8;
  padding: 8px;
}
.card {
  margin: 10px;
  padding: 0px 10px;
  border: initial;
  background: #fff;
  border-radius: calc(0.15rem - 1px);
  box-shadow: 0 1px 15px rgb(0 0 0 / 4%), 0 1px 6px rgb(0 0 0 / 4%);
}
.search-content {
  padding: 8px;
  max-width: 100%;
  flex: 0 0 auto;
  box-sizing: border-box;
  flex-wrap: wrap;
  background: #006588;
  color: white;
}
.card-body {
  padding: 10px 0px;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
}
p {
  color: #333;
}
a {
  color: #333;
}
</style>