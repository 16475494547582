<template>
  <div class="container">
    <div class="search-content">
      <img
        @click="goBack()"
        width="30px"
        height="auto"
        style="float: left; position: absolute; top: 10px; left: 10px"
        :src="require('@/assets/back.png')"
      />
      <h2 class="title">Por favor ingrese la información de soporte</h2>
    </div>
    <div class="content">
      <form action="#" @submit="checkForm" method="post">
        <div class="form-group">
          <label class="hide" for="rut">Ingrese su RUT</label>
          <input
            @blur="formatRutBlur"
            type="text"
            v-model="form.rut"
            placeholder="Ingrese su RUT"
            class="form-control"
            name="rut"
          />
          <small v-if="!validation.rut">Debe ingresar un RUT</small>
        </div>
        <div class="form-group" v-if="this.$store.state.sesion.rut.length == 0">
          <label class="hide" for="nombre">Ingrese su nombre</label>
          <input
            type="text"
            v-model="form.nombre"
            placeholder="Ingrese su nombre"
            class="form-control"
            name="nombre"
          />
        </div>
        <div class="form-group">
          <label class="hide" for="correo">Ingrese su Correo</label>
          <input
            type="email"
            v-model="form.correo"
            placeholder="Ingrese su correo"
            class="form-control"
            name="correo"
          />
          <small v-if="!validation.correo"
            >Debe ingresar un correo valido</small
          >
        </div>
        <div class="form-group">
          <label class="hide" for="colegio_id">Seleccione su Colegio</label>
          <select
            type="text"
            v-model="form.colegio_id"
            placeholder="Ingrese su colegio"
            class="form-control"
            name="colegio_id"
          >
            <option selected value="">Seleccione un colegio</option>
            <option :value="colegio.id" v-for="colegio of colegios">
              {{ colegio.name }}
            </option>
          </select>
          <small v-if="!validation.colegio_id"
            >Debe seleccionar un colegio</small
          >
        </div>
        <div class="form-group">
          <label class="hide" for="sistema_afectado">Asunto</label>
          <input
            type="text"
            v-model="form.asunto"
            placeholder="Asunto"
            class="form-control"
            name="asunto"
          />
          <small v-if="!validation.colegio_id">Debe ingresar un asunto</small>
        </div>
        <div class="form-group">
          <label class="hide" for="sistema_afectado">Descripción</label>
          <input
            type="text"
            v-model="form.descripcion"
            placeholder="Descripción"
            class="form-control"
            name="descripcion"
          />
          <small v-if="!validation.descripcion"
            >Debe ingresar una descripción</small
          >
        </div>
        <div class="form-group">
          <label>Adjuntar imagen(opcional)</label>
          <p>
            <FileInput v-model="form.adjunto"></FileInput>
          </p>
        </div>
        <div class="form-group">
          <button v-if="!loading" class="btn-support">Enviar soporte</button>
          <button v-if="loading" disabled class="btn-support">
            <div class="lds-ellipsis">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </button>
          <div v-if="success" class="message-success">
            Soporte ingresado correctamente
          </div>
          <div v-if="error" class="message-error">
            Lo sentimos, no se registro la solicitud
          </div>
        </div>
      </form>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import FileInput from "@/components/FileInput.vue";
import { mapActions } from "vuex";
const apiUrl = process.env.VUE_APP_GESTIONA_URL;
export default {
  data() {
    return {
      success: false,
      error: false,
      loading: false,
      form: {
        rut: "",
        correo: "",
        colegio_id: "",
        asunto: "",
        descripcion: "",
        adjunto: null,
      },
      validation: {
        rut: true,
        correo: true,
        colegio_id: true,
        asunto: true,
        descripcion: true,
      },
      colegios: [
        { id: 1, name: "Palmares Central" },
        { id: 2, name: "Palmares Oriente" },
        { id: 3, name: "Palmares Los Condores" },
        { id: 4, name: "San Sebastian" },
        { id: 5, name: "Eyzaguirre" },
      ],
    };
  },
  components: {
    FileInput,
  },
  methods: {
    ...mapActions(["registerSupport"]),
    goBack() {
      window.history.length > 1 ? this.$router.go(-1) : this.$router.push("/");
    },
    checkForm: function (e) {
      if (this.form.rut == "") {
        this.validation.rut = false;
      } else {
        this.validation.rut = true;
      }
      if (this.form.correo == "" || this.validEmail(this.form.correo)) {
        this.validation.correo = false;
      } else {
        this.validation.correo = true;
      }
      if (this.form.colegio_id == "") {
        this.validation.colegio_id = false;
      } else {
        this.validation.colegio_id = true;
      }
      if (this.form.asunto == "") {
        this.validation.asunto = false;
      } else {
        this.validation.asunto = true;
      }
      if (this.form.descripcion == "") {
        this.validation.descripcion = false;
      } else {
        this.validation.descripcion = true;
      }

      e.preventDefault();
      if (
        this.form.rut &&
        this.form.correo &&
        this.form.colegio_id &&
        this.form.asunto &&
        this.form.descripcion &&
        this.validation.rut &&
        this.validation.correo &&
        this.validation.colegio_id &&
        this.validation.asunto &&
        this.validation.descripcion
      ) {
        this.loading = true;
        this.success = false;
        this.error = false;
        let formData = new FormData();
        formData.append("adjunto", this.form.adjunto);
        formData.append("nombre", this.form.nombre);
        formData.append("rut", this.formatRut(this.form.rut));
        formData.append("email", this.form.correo);
        formData.append("colegio_id", this.form.colegio_id);
        formData.append("titulo", this.form.asunto);
        formData.append("comentario", this.form.descripcion);
        formData.append("tiposoporte_id", 1);
        formData.append("sistema_id", this.$store.state.sesion.system);

        axios
          .post(`${apiUrl}/soporte-tecnico/soportes/addAjax`, formData)
          .then((r) => r.data)
          .then((res) => {
            if (res.soporte !== "undefined") {
              this.loading = false;
              this.success = true;
              this.error = false;
              this.form.rut = "";
              this.form.correo = "";
              this.form.nombre = "";
              this.form.colegio_id = "";
              this.form.asunto = "";
              this.form.descripcion = "";
              this.registerSupport(res.soporte);
              this.$router.push("/support-success");
            } else {
              this.loading = false;
              this.success = false;
              this.error = true;
            }
          })
          .catch((err) => {
            this.loading = false;
            this.success = false;
            this.error = true;
          });
      }
    },
    validEmail: function (email) {
      var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return !re.test(email);
    },
    formatRut: function (val) {
      let rut_completo = val.replace(/[^0-9\k\K]/g, "");
      let rut = rut_completo.slice(0, -1);
      let digito_verificador = rut_completo.charAt(rut_completo.length - 1);
      return rut + "-" + digito_verificador;
    },
    formatRutBlur: function () {
      let rut_completo = this.form.rut.replace(/[^0-9\k\K]/g, "");
      let rut = rut_completo.slice(0, -1);
      let digito_verificador = rut_completo.charAt(rut_completo.length - 1);
      this.form.rut = rut + "-" + digito_verificador;
    },
  },
  watch: {
    "form.rut": function (val, oldVal) {
      this.form.rut = val.replace(/[^0-9\-\k\K]/g, "");
    },
  },
  mounted() {
    this.form.rut = this.$store.state.sesion.rut;
  },
};
</script>
<style scoped>
.hide {
  display: none;
}
.message-success {
  padding: 15px;
  background: #129044;
  text-align: center;
  color: #ffffff;
  transition: display 0s, opacity 0.5s linear;
}
.message-error {
  padding: 15px;
  background: #d39040;
  text-align: center;
  color: #ffffff;
  transition: display 0s, opacity 0.5s linear;
}
small {
  color: #d07c40;
}
.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  box-sizing: border-box;
}
.form-group {
  padding: 10px;
  margin: 10px;
}
.title {
  font-size: 1em;
  margin: 0 35px;
  color: #fff;
  display: inline-block;
  box-shadow: 0 25px 0 -23px #5cc7a8;
  padding: 15px;
}
.search-content {
  padding: 16px;
  max-width: 100%;
  flex: 0 0 auto;
  box-sizing: border-box;
  flex-wrap: wrap;
  background: #006588;
  color: white;
}
.container {
  display: flex;
  flex-direction: column;
  height: -webkit-fill-available;
}
.content {
  overflow-x: auto;
}
.btn-support {
  cursor: pointer;
}
.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 12px;
}

#error_seguimiento {
  color: #ff0000;
}

.lds-ellipsis div {
  position: absolute;
  top: 0px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #fff;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}

.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}

@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(0);
  }
}

@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }

  100% {
    transform: translate(24px, 0);
  }
}
</style>