import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Solution from "../views/Solution.vue";
import SupportForm from "../views/SupportForm.vue";
import SupportSuccess from "../views/SupportSuccess.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "default",
    redirect: "/app/null/null",
  },
  {
    path: "/app/:sistema/:rut",
    name: "Home",
    component: Home,
  },
  {
    path: "/solution/:id",
    name: "Solution",
    component: Solution,
  },
  {
    path: "/support-form",
    name: "SupportForm",
    component: SupportForm,
  },
  {
    path: "/support-success",
    name: "SupportSuccess",
    component: SupportSuccess,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
