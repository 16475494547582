export default {
  state: {
    support: {},
  },
  mutations: {
    setSupport(state, payload) {
      state.support = payload;
    },
  },
  actions: {
    registerSupport({ commit }, support) {
      commit("setSupport", support);
    },
  },
};
