import axios from "axios";
const apiUrl = process.env.VUE_APP_API_URL;
import _ from "lodash";
export default {
  state: {
    loading: false,
    error: false,
    success: null,
    search: "",
    solution: {},
    solutions: [],
  },
  mutations: {
    setSolutions(state, payload) {
      state.solutions = payload.solutions;
      state.loading = false;
      state.success = true;
      state.error = false;
    },
    setSolution(state, payload) {
      console.log(payload);
      state.solution = payload.solution;
      state.loading = false;
      state.success = true;
      state.error = false;
    },
    setSolutionsLoading(state) {
      state.loading = true;
    },
    setSolutionsError(state) {
      state.loading = false;
      state.error = true;
    },
  },
  actions: {
    searchSolutionsDebounce: _.debounce(function({ commit }, searchData) {
      if (searchData.search.length > 3) {
        axios
          .post(`${apiUrl}/solutions`, searchData)
          .then((r) => r.data)
          .then((res) => {
            console.log(res);
            commit("setSolutions", {
              solutions: res,
            });
          })
          .catch((err) => commit("setSolutionsError"));
      } else {
        commit("setSolutions", {
          solutions: [],
        });
      }
    }, 1000),
    searchSolutions({ commit, dispatch }, searchData) {
      commit("setSolutionsLoading");
      dispatch("searchSolutionsDebounce", searchData);
    },
    getSolution({ commit }, id) {
      axios
        .get(`${apiUrl}/solutions/${id}`)
        .then((r) => r.data)
        .then((res) => {
          commit("setSolution", {
            solution: res,
          });
        })
        .catch((err) => commit("setSolutionsError"));
    },
  },
};
